import React, { useState } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import moment from 'moment';
import { Row, Col } from 'antd';
import siteConfig from "../../../data/SiteConfig";


const ShowMore = (props) => {
  // no show more button if not enough to show
  console.log(props.count);
  if (props.count < 7) {
    console.log("under 7");
    return <div></div>
  }

  const [buttonVis, setButtonVis] = useState(0);

  const morePosts = (props) => {
    console.log("moreposts");
    const notes = document.getElementsByClassName('hide');
    let increment = 6;
    
    if (notes.length < increment) {
      let increment = notes.length; 
    }
    
    for (let index = 0; index < increment; index++) {  
      if (notes[index]) {
        notes[index].classList.remove("hide");  
      }
      if (notes.length < 1) {
        setButtonVis("hide");
      }
    }
  }
  return (
      <button onClick={morePosts} className={buttonVis}>
          SHOW MORE
      </button>
  ) 
}
class PostListing extends React.Component {

  constructor(props) {
    super(props);
    this.card = React.createRef();  
  }

  componentDidMount() {
    const _this = this;
    if (document.getElementsByClassName('note').length > 1) {
      for (let index = 0; index < 6; index++) {
        if (document.getElementsByClassName('note')[index]) {
          document.getElementsByClassName('note')[index].classList.remove("hide");
        }
      }
      
    }
  }

  
  getPostList() {
    const postList = [];
    this.props.postEdges.forEach(postEdge => {
      postList.push({
        path: `/labnotes/${postEdge.node.data.slug}`,
        tags: postEdge.node.data.tags,
        category: postEdge.node.data.category,
        image: postEdge.node.data.image ? postEdge.node.data.image.localFiles[0].childImageSharp: this.props.defaultpic,
        title: postEdge.node.data.title,
        date: siteConfig.dateFormat ? moment(postEdge.node.data.date).format(siteConfig.dateFormat) : postEdge.node.data.date,
        author: postEdge.node.data.author ? postEdge.node.data.author : null,
        postMarkdown: postEdge.node.data.postMarkdown,
        snippet: postEdge.node.data.snippet,
        ref: postEdge.node.recordId
      });
    });
    return postList;
  }

  render() {
    const postList = this.getPostList();

    //show all notes if note enough for a page
    if (postList.length < 7) {
      this.postClass = "note"
    } else {
      this.postClass = "note hide"
    }
    
    return (
      <Row className='notes' id="notes" key='18787'>
        {
        postList.map(post => (
          <Col key={post.title} xs={24} lg={12} xl={8} className={this.postClass} ref={post.ref} style={{display: `flex`, flexDirection: `column`}}>
            <Link to={post.path} key={post.title}>
              <Img fluid={post.image.fluid} alt={post.title} />
              <div className="note-title">
                <h2>{post.title}</h2>
              </div>
            </Link>     
              <div className="sub-excerpt">
                <div class="sub-excerpt-dateline">
                  {post.date} <span className="author"> by {post.author}</span>
                </div>
              <div>{post.snippet}</div>
              </div>
              <hr className="zigzag" />
          </Col>
        ))}
          {/* <Col span={24} className="show-more-button">
            <ShowMore count={postList.length}/>
          </Col> */}
      </Row>
    );

  }
}

export default PostListing;
