import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

class LabNotesPage extends React.Component {
  render() {
    const postEdges = this.props.data.allAirtable.edges;
    const defPic = '/blm_rivers.png'; 

    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          <h1>Lab Notes</h1>
          <PostListing postEdges={postEdges} defaultpic={defPic} />
        </div>
      </Layout>
    );
  }
}

export default LabNotesPage;

//* eslint no-undef: "off" */
export const pageQuery = graphql`
query IndexQuery($dateFormat: String) {
    allAirtable(limit: 2000, sort: {fields: data___date, order: DESC}, filter: {data: {}, table: {eq: "lab_notes"}}) {
      edges {
        node {
          recordId
          data {
            slug
            date(formatString: $dateFormat)
            title
            author
            snippet
            status
            postMarkdown {
              childMarkdownRemark {
                html
                excerpt(format: PLAIN)
                timeToRead
              }
            }
            image {
              id
              localFiles {
                childImageSharp {
                  fluid(jpegProgressive: true, maxWidth: 368, maxHeight: 199) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allImageSharp(filter: {id: {eq: "f1791f03-281e-5ce7-aff1-01b95eadc4da"}}) {
      edges {
        node {
          fixed(height: 400, width: 370, cropFocus: CENTER) {
            ...GatsbyImageSharpFixed
          }
          id
        }
      }
    }
  }
`;
